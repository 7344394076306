<template>
    <div class="filter">
        <div class="filter__label">Цена, руб </div>
        <div class="filter__range">
            <div id="slider"></div>
        </div>
    </div>
</template>
  
  <script>
    import noUiSlider from 'nouislider';
    import 'nouislider/dist/nouislider.css'; 
    export default {
        name: "Filters",
        data () {
            return {
                slider: null,
            }
        },
        mounted () {
            this.slider = noUiSlider.create(document.getElementById('slider'), {
                start: [0, 1000000],
                connect: true,
                range: {
                    'min': 0,
                    'max': 1000000
                }
            });
            }
        }
  </script>
  
<style lang="sass">
@import "../sass/functions.sass"
.filter 
    font-family: "SB Sans Text"
    &__label 
        font-size: vw(14px)
        font-weight: 600
        line-height: vw(20px)
    &__range 
        margin-top: vw(25px)
.noUi-horizontal
    height: vw(2px)
    width: vw(277px)
.noUi-horizontal .noUi-handle
    height: vw(28px)
    width: vw(28px)
    border-radius: vw(50px)
    background: #FFF
    drop-shadow: (0px 2px 4px rgba 8, 9, 10, 0.06)
    cursor: pointer
    top: vw(-10px)
    &:after 
        display: none 
    &:before 
        display: none
.noUi-connects
    height: vw(2px)
    background: #D4DADF
.noUi-connect 
    background: #9B38DC
</style>