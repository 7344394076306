<template>
  <div class="navigation">
    <div class="container">
      <div class="navigation__list">
        <div class="navigation__item">
            <a href="#" class="navigation__link navigation__link--active">Все квартиры</a>
        </div>
        <div class="navigation__item">
            <a href="#" class="navigation__link">Дом построен, есть отделка</a>
        </div>
        <div class="navigation__item">
            <a href="#" class="navigation__link">Две ванные комнаты</a>
        </div>
        <div class="navigation__item">
            <a href="#" class="navigation__link">Площадь 90+ м2</a>
        </div>
        <div class="navigation__item">
            <a href="#" class="navigation__link">Французский балкон</a>
        </div>
        <div class="navigation__item">
            <a href="#" class="navigation__link">Большие игровые площадки</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
	name: "Navigation",
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
.navigation 
    &__list 
      display: flex
      font-family: "SB Sans Text"
      margin-top: vw(36px)
      background: #F2F5F7
      border-radius: vw(25px)
    &__item
      padding: vw(6px) vw(3px) vw(6px) vw(6px)
      display: flex
      a 
        text-decoration: none
        padding: vw(13px) vw(16px)
        color: #8C959C
        font-size: vw(14px)
        font-weight: 600
        line-height: vw(18px)
    &__link
      &--active 
        color: #1C1F22
        background: #FFFFFF
        border-radius: vw(16px)
</style>