<template>
	<div class="catalog">
        <div class="container">
            <div class="catalog__content">   
                <div class="catalog__filters">
                    <Filters />
                </div>
                <div class="catalog__list">
                    <div class="banner">
                        <div class="banner__title">Квартиры<br> с отделкой</div>
                        <div class="banner__text">в построенных домах</div>
                    </div>
                    <div class="cards">
                        <div class="card">
                            <div class="card__image">
                                <img src="@/assets/img/Card1.png" alt="">
                            </div>
                            <div class="card__price">8 955 590 ₽</div>
                            <div class="card__text">Студия 21,7 м2</div>
                            <div class="card__price-part">33 565 ₽ в мес.</div>
                            <div class="card__location">
                                <div class="card__location-icon">
                                    <img src="@/assets/img/icon.svg" alt="">
                                </div>
                                <div class="card__location-text">Большая Очаковская 2</div>
                            </div>
                            <div class="card__text card__text--homely">Готовая отделка</div>
                            <div class="card__text">г. Москва</div>
                            <div class="card__text">Заселение до 2 кв. 2025 </div>
                            <div class="card__button"> 
                                <a href="#">Подробнее</a>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card__image">
                                <img src="@/assets/img/Card2.png" alt="">
                            </div>
                            <div class="card__price">10 387 680 ₽</div>
                            <div class="card__text">1-комнатная 32,3 м²</div>
                            <div class="card__price-part">38 932 ₽ в мес.</div>
                            <div class="card__location">
                                <div class="card__location-icon">
                                    <img src="@/assets/img/icon.svg" alt="">
                                </div>
                                <div class="card__location-text">Новое Очаково</div>
                            </div>
                            <div class="card__text card__text--homely">Предчистовая отделка</div>
                            <div class="card__text">г. Москва</div>
                            <div class="card__text">Заселение до 4 кв. 2024</div>
                            <div class="card__button"> 
                                <a href="#">Подробнее</a>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card__image">
                                <img src="@/assets/img/Card3.png" alt="">
                            </div>
                            <div class="card__price">21 233 536 ₽</div>
                            <div class="card__text">2-комнатная 61,87 м²</div>
                            <div class="card__price-part">102 508 ₽ в мес.</div>
                            <div class="card__location">
                                <div class="card__location-icon">
                                    <img src="@/assets/img/icon.svg" alt="">
                                </div>
                                <div class="card__location-text">Vangarden</div>
                            </div>
                            <div class="card__text card__text--homely">Предчистовая отделка</div>
                            <div class="card__text">г. Москва</div>
                            <div class="card__text">Заселение до 2 кв. 2025</div>
                            <div class="card__button"> 
                                <a href="#">Подробнее</a>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card__image">
                                <img src="@/assets/img/Card4.png" alt="">
                            </div>
                            <div class="card__price">25 014 360 ₽</div>
                            <div class="card__text">3-комнатная 73,68 м²</div>
                            <div class="card__price-part">123 549 ₽ в мес.</div>
                            <div class="card__location">
                                <div class="card__location-icon">
                                    <img src="@/assets/img/icon.svg" alt="">
                                </div>
                                <div class="card__location-text">Матвеевский парк</div>
                            </div>
                            <div class="card__text card__text--homely">Предчистовая отделка</div>
                            <div class="card__text">г. Москва</div>
                            <div class="card__text">Заселение до 3 кв. 2024</div>
                            <div class="card__button"> 
                                <a href="#">Подробнее</a>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card__image">
                                <img src="@/assets/img/Card1.png" alt="">
                            </div>
                            <div class="card__price">8 955 590 ₽</div>
                            <div class="card__text">Студия 21,7 м2</div>
                            <div class="card__price-part">33 565 ₽ в мес.</div>
                            <div class="card__location">
                                <div class="card__location-icon">
                                    <img src="@/assets/img/icon.svg" alt="">
                                </div>
                                <div class="card__location-text">Большая Очаковская 2</div>
                            </div>
                            <div class="card__text card__text--homely">Готовая отделка</div>
                            <div class="card__text">г. Москва</div>
                            <div class="card__text">Заселение до 12 мая 2025</div>
                            <div class="card__button"> 
                                <a href="#">Подробнее</a>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card__image">
                                <img src="@/assets/img/Card2.png" alt="">
                            </div>
                            <div class="card__price">10 387 680 ₽</div>
                            <div class="card__text">1-комнатная 32,3 м²</div>
                            <div class="card__price-part">38 932 ₽ в мес.</div>
                            <div class="card__location">
                                <div class="card__location-icon">
                                    <img src="@/assets/img/icon.svg" alt="">
                                </div>
                                <div class="card__location-text">Новое Очаково</div>
                            </div>
                            <div class="card__text card__text--homely">Предчистовая отделка</div>
                            <div class="card__text">г. Москва</div>
                            <div class="card__text">Заселение до 30 декабря 2024</div>
                            <div class="card__button"> 
                                <a href="#">Подробнее</a>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card__image">
                                <img src="@/assets/img/Card3.png" alt="">
                            </div>
                            <div class="card__price">21 233 536 ₽</div>
                            <div class="card__text">2-комнатная 61,87 м²</div>
                            <div class="card__price-part">102 508 ₽ в мес.</div>
                            <div class="card__location">
                                <div class="card__location-icon">
                                    <img src="@/assets/img/icon.svg" alt="">
                                </div>
                                <div class="card__location-text">Vangarden</div>
                            </div>
                            <div class="card__text card__text--homely">Предчистовая отделка</div>
                            <div class="card__text">г. Москва</div>
                            <div class="card__text">Заселение до 30 мая 2024</div>
                            <div class="card__button"> 
                                <a href="#">Подробнее</a>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card__image">
                                <img src="@/assets/img/Card4.png" alt="">
                            </div>
                            <div class="card__price">25 014 360 ₽</div>
                            <div class="card__text">3-комнатная 73,68 м²</div>
                            <div class="card__price-part">123 549 ₽ в мес.</div>
                            <div class="card__location">
                                <div class="card__location-icon">
                                    <img src="@/assets/img/icon.svg" alt="">
                                </div>
                                <div class="card__location-text">Матвеевский парк</div>
                            </div>
                            <div class="card__text card__text--homely">Предчистовая отделка</div>
                            <div class="card__text">г. Москва</div>
                            <div class="card__text">Заселение до 30 марта 2024</div>
                            <div class="card__button"> 
                                <a href="#">Подробнее</a>
                            </div>
                        </div>
                    </div>
                    <div class="catalog__list-button"><a href="#">Смотреть все квартиры</a></div>
                </div> 
            </div>
        </div>
	</div>

</template>

<script>
  import Filters from '@/components/Filters'
  export default {
	name: "Catalog",
    components: {
        Filters,
    }
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
.catalog 
    &__content
        display: flex
        margin-top: vw(47px)
        font-family: "SB Sans Text"
    &__filters 
        max-width: vw(278px)
        margin-right: vw(21px)
    &__list 
        &-button 
            a 
                text-decoration: none
                background: #F2F5F7
                color: #FC4C02
                font-size: vw(14px)
                font-weight: 600
                line-height: vw(20px)
                padding: vw(14px) vw(477px)
                border-radius: vw(32px)
.filter 
    display: flex
    flex-direction: column
    width: vw(278px)
.banner 
    font-family: "Graphik LC"
    color: #FFF
    background-image: url("../assets/img/Bgr.png")
    background-repeat: no-repeat
    background-size: cover
    border-radius: vw(28px)
    height: vw(174px)
    width: vw(1130px)
    &__title
        font-size: vw(37px)
        font-weight: 600
        line-height: vw(37px)
        letter-spacing: vw(-0.75px)
        padding-left: vw(28px)
        padding-top: vw(29px)
    &__text 
        font-size: vw(18px)
        line-height: vw(16px)
        letter-spacing: vw(-0.36px)
        padding-left: vw(31px)
        padding-top: vw(23px)
.cards 
    margin-top: vw(29px)
    display: flex
    flex-wrap: wrap
    justify-content: space-beetwen 
.card
    width: vw(275px)
    border-radius: vw(16px)
    font-family: "SB Sans Text"
    font-size: vw(12px)
    font-weight: 400
    line-height: vw(16px)
    margin-right: vw(7px)
    margin-bottom: vw(43px)
    padding-left: vw(10px)
    padding-right: vw(10px)
    &__image 
        object-fit: cover
        width: vw(255px)
        height: vw(255px)
        img 
            width: 100%
            height: 100%
            border-radius: vw(8px)
            padding-top: vw(10px)
    &__price 
        font-size: vw(18px)
        font-weight: 750
        line-height: vw(24px)
        padding-top: vw(28px)
        padding-bottom: vw(8px)
        font-family: "SB Sans Display"
        &-part
            background: #A8FDE0
            width: vw(110px)
            padding-top: vw(4px)
            padding-bottom: vw(3px)
            padding-left: vw(6px)
            border-radius: vw(4px)
            margin-bottom: vw(8px)
            position: relative
            &::after
                content: "i"
                font-size: vw(8px)
                line-height: vw(11px)
                background: #D9D9D9
                border-radius: 50%
                padding: vw(2px) vw(3px)
                position: absolute
                top: vw(-5px)
                right: vw(0.5px)
    &__text 
        padding-bottom: vw(8px)
        &--homely 
            color: #8C959C
    &__location 
        display: flex 
        padding-bottom: vw(6px)
        &-icon 
            width: vw(16px)
            padding-right: vw(2px)
            padding-left: vw(2px)
            img 
                width: 100%
        &-text 
            padding-top: vw(4px)
    &__button
        margin-top: vw(20px)
        margin-bottom: vw(16px)
        margin-left: vw(5px)
        a 
            text-decoration: none
            background: #FC4C02
            color: #FFF
            padding: vw(8px) vw(19px)
            border-radius: vw(32px)
            font-size: vw(14px)
            font-weight: 600
            line-height: vw(20px)
</style>