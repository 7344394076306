<template>
  <div class="page">
    <TheHeader />
    <Hero />
    <Navigation />
    <Catalog />
    
  </div>
</template>

<script>
  import TheHeader from '@/components/TheHeader'
  import Hero from '@/components/Hero'
  import Navigation from '@/components/Navigation'
  import Catalog from '@/components/Catalog'
  export default {
    name: "Main",
    components: {
      TheHeader,
      Hero,
      Navigation,
      Catalog, 
      
    }
  }
</script>