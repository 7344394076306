<template>
	<div class="hero">
		<div class="container">
			<div class="hero__content">
				<div class="content">
					<div class="hero__title">Хороший <br>выбор</div>
					<div class="hero__text">Квартиры для выгодной<br> покупки и счастливого <br> новоселья</div>
					<div class="hero__logo">
						<img src="@/assets/img/Pik.svg" alt="">
					</div>
				</div>
				<div class="hero__image">
					<img src="@/assets/img/Image.png" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
	name: "Hero",
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
.hero 
	&__content
		display: flex
		justify-content: space-between
		font-family: "Graphik LC"
		background-color: rgba(250, 250, 250, 1)
		border-radius: vw(45px)
	.content 
		width: vw(714px)
	&__title 
		font-size: vw(75px)
		font-weight: 600
		line-height: vw(75px)
		color: rgba(50, 50, 50, 1)
		padding-left: vw(49px)
		padding-top: vw(46.5px)
	&__text 
		font-size: vw(38px)
		font-weight: 500
		line-height: vw(40px)
		color: rgba(140, 130, 121, 1)
		padding-top: vw(30px)
		padding-left: vw(53px)
	&__logo 
		width: vw(179.58px)
		padding-top: vw(79px)
		margin-left: vw(55px)
		padding-bottom: vw(50px)
		img 
			width: 100%
	&__image 
		width: vw(714px)
		height: vw(532px)
		object-fit: cover
		img 
			width: 100%
			height: 100%
			border-radius: vw(45px)
</style>