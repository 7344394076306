<template>
	<div class="header">
		<div class="container">
			<div class="header__content">
				<div class="header__logo">
					<img src="@/assets/img/logo.svg" alt="">
				</div>
				<div class="header__menu">
					<a href="#" class="header__menu-item header__menu-item--active">
						<div class="header__menu-icon header__menu-icon--1">
							<img src="@/assets/img/Raffle.svg" alt="">
						</div>
						<div class="header__menu-name">Розыгрыш</div>
					</a>
				</div>
				<div class="header__menu">
					<a href="#" class="header__menu-item">
						<div class="header__menu-icon header__menu-icon--2">
							<img src="@/assets/img/Percent.svg" alt="">
						</div>
						<div class="header__menu-name">Мега Выгода</div>
					</a>
				</div>
				<div class="header__menu">
					<a href="#" class="header__menu-item">
						<div class="header__menu-icon header__menu-icon--3">
							<img src="@/assets/img/Carrot.svg" alt="">
						</div>
						<div class="header__menu-name">Супермаркет</div>
					</a>
				</div>
				<div class="header__menu">
					<a href="#" class="header__menu-item">
						<div class="header__menu-icon header__menu-icon--4">
							<img src="@/assets/img/Fire.svg" alt="">
						</div>
						<div class="header__menu-name">Акции</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
	name: "Header",
  }
</script>

<style lang="sass">
@import "../sass/functions.sass"
.header
	font-family: "SB Sans Text"
	margin-top: vw(15px)
	margin-bottom: vw(17px)
	&__content
		display: flex
		align-items: center
	&__logo
		width: vw(90px)
		margin-right: vw(24px)
		img
			width: 100%
	&__menu 
		margin-right: vw(7px)
		a 
			text-decoration: none
		&-item 
			display: flex 
			align-items: center
			background: rgba(242, 245, 247, 1)
			color: rgba(73, 79, 84, 1)
			border-radius: vw(16px) 
			border: vw(1px) solid rgba(226, 230, 233, 1)
			padding: vw(10.5px) vw(13.5px)
			&--active 
				color: rgba(255, 255, 255, 1)
				background: rgba(105, 51, 176, 1)
		&-icon 
			margin-right: vw(7px)
			height: vw(25px)
			display: flex 
			align-items: center
			img
				width: 100%
			&--1 
				width: vw(25px)
			&--2 
				width: vw(20px)
			&--3 
				width: vw(24px)
			&--4 
				width: vw(24px)
		&-name 
			font-size: vw(14px)
			line-height: vw(18px)
			font-weight: 500
</style>
